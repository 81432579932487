header{
    background-color: #1e1e1e;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    color: white;

}

#nav-logo{
    height: 50px;
    filter: invert(1);
}

.nav-link{
    color: #fff;
    text-decoration: none;
    margin: 0 1rem;
    font-weight: 300;
    font-size: 18px;
    font-family: 'Proxima';
}

.hamburger-menu{
    display: none;
}

@media only screen and (max-width: 600px) {
    #desktop-nav{
        display: none;
    }

    .hamburger-menu{
        display: block;
        z-index: 999;
    }

    #menu__toggle {
        opacity: 0;
      }
      #menu__toggle:checked + .menu__btn > span {
        transform: rotate(45deg);
      }
      #menu__toggle:checked + .menu__btn > span::before {
        top: 0;
        transform: rotate(0deg);
      }
      #menu__toggle:checked + .menu__btn > span::after {
        top: 0;
        transform: rotate(90deg);
      }
      #menu__toggle:checked ~ .menu__box {
        left: 0 !important;
      }
      .menu__btn {
        position: fixed;
        top: 40px;
        right: 20px;
        width: 26px;
        height: 26px;
        cursor: pointer;
        z-index: 1;
      }
      .menu__btn > span,
      .menu__btn > span::before,
      .menu__btn > span::after {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transition-duration: .25s;
      }
      .menu__btn > span::before {
        content: '';
        top: -8px;
      }
      .menu__btn > span::after {
        content: '';
        top: 8px;
      }
      .menu__box {
        display: block;
        position: fixed;
        top: 0;
        left: -100%;
        width: 300px;
        height: 100%;
        margin: 0;
        padding: 80px 0;
        list-style: none;
        background-color: #ECEFF1;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, .4);
        transition-duration: .25s;
      }
      .menu__item {
        display: block;
        padding: 12px 24px;
        color: #333;
        font-weight: 300;
        font-size: 18px;
        font-family: 'Proxima';
        text-decoration: none;
        transition-duration: .25s;
      }
      .menu__item:hover {
        background-color: #CFD8DC;
      }
  }