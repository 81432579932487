.dash-stat{
    background-color: #e0dad7;
    width: 300px;
    height: 250px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 1rem;
}

.dash-stat h2{
    font-family: 'Arual';
}

#dashboard{
    display: flex;
    flex-wrap: wrap;

}