#asanas{
    display: flex;
    padding: 1rem;
    justify-content: center;
    flex-direction: column;
}

#asana-form{
    display: flex;
    flex-direction: column;
    width: 600px;
    max-width: 95vw;
    margin: 1rem auto ;
}

#asana-form > * {
    margin: 10px 0
}

#asana-form select, #asana-form input{
    height: 50px;
    border-radius: 8px;
    border: solid 1px #f1f1f1;
    padding-left: 10px ;
}

#asana-form input[type=checkbox]{
    height: 20px;
    width: 20px;
}