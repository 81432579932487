*{
  box-sizing: border-box;
  font-family: 'Proxima';
}

.outlet{
  padding: 1rem;
  background-color: #fafafa;
  min-height: calc(100vh - 90px);
  text-align: center;
}

button:hover, a:hover{
  cursor: pointer;
}

.om-btn{
  border: unset;
  background-color: #a28f89;
  border-radius: 5px;
  padding: 10px;
  width: 150px;
  height: 40px;
  color: #fff;
}

.cancel-btn{
  background-color: crimson;
  border: unset;
  color: white;
  border-radius: 5px;
  padding: 10px;
  min-width: 150px;
  height: 40px;
}

.primary-btn{
  background-color: #e0dad7;
  color: white;
  height: 48px;
  border-radius: 8px;
  border: unset;
  margin: 1rem 0;
  font-family: 'Satoshi', sans-serif;
  font-size: 18px;
  transition: 0.6s;
  padding: 0 15px;
  color: #272838;
  letter-spacing: -0.5px;
  max-width: 90%;
}

.om-row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.om-input{
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  background-color: #fdfdfd;
  height: 48px;
  padding-left: 10px;
  margin: 1rem 0;

}
.om-header{
  font-family: 'Satoshi', sans-serif;
  font-size: 32px;
  letter-spacing: -0.5px;
  color: #272838;
  font-weight: 400;
}

.tab-container{
  height: 68px;
  border: 0.4px solid #d4d4d4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  margin: 1rem 0;
}

.tab{
  height: 50px;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.tab-active{
  height: 50px;
  padding:   1rem;
  background-color: #e0dad7;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border-radius: 4px;
}

.tab-active:hover, .tab:hover{
  cursor: pointer;
}

.tab-square{
  height: 30px;
  width: 30px;
  border: 1px solid rgb(190, 190, 190);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

label{
  font-size: 0.8rem;
}

table{
  width: 1200px;
  max-width: 95%;
  margin: 1rem auto;
  border-collapse: collapse;
}

td, th{
  text-align: left;
  height: 40px;
  padding: 5px;
  margin: 0;
}

 tr:nth-child(even){
  background-color: #e0dad7;
}

@font-face {
  font-family: 'Arual';
  src: local('Arual'), url(./Assets/fonts/Arual.ttf) format('truetype');
}
@font-face {
  font-family: 'Proxima';
  src: local('Proxima'), url(./Assets/fonts/proxima.otf) format('opentype');
}


