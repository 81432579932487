.msg-input{
    width: 550px;
    max-width: 95vw;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    padding-left: 10px;
    margin: 0.5rem;

}

#msg-body{
    width: 550px;
    max-width: 95vw;
    min-height: 400px;
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    padding: 10px;
    margin: 0.5rem;
    resize: none;
}

#message{
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.send-btn{
    background-color: #1e1e1e;
    color: white;
    padding: 10px;
    border-radius: 8px;
    border: unset;
    margin: 10px;
}